
.loader {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.dot {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #C3DCF1;

    &__first {
        animation: jumpingAnimation 0.6s 0.1s ease-in infinite;
    }

    &__second {
        animation: jumpingAnimation 0.6s 0.2s ease-in infinite;
    }

    &__third {
        animation: jumpingAnimation 0.6s 0.3s ease-in infinite;
    }
}

@keyframes jumpingAnimation {
    0% {
      transform: translate3d(0, 0,0);
    }
    50% {
      transform: translate3d(0, -5px,0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
