.about-modal {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    z-index: 4;
    padding: 64px;
    padding-top: 0px;
    padding-bottom: 48px;
    border-radius: 16px;
    width: 500px;
    height: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
    }
}

.modal-button {
    position: absolute;
    outline: none;
    border: none;
    width: 36px;
    height: 36px;
    top: 24px;
    right: 24px;
    background-color:#376FAB;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.modal-button:hover {
    transform: translateY(-1px);
    color: white;
    background-color:#42B3F2;
}

.modal-button:active {
    transform: translateY(0px);
    color: white;
    background-color: white;
}

.modal-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #14457A;
}

.modal-header {
    margin-top: 6px;
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #376FAB;
}

.modal-picture {
    width: 42px;
    height: 40px;
}