@import "../../scss/mixins";

.page {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;

    &__gradient {
        pointer-events: none;
        background: linear-gradient(0deg, #E8F7FF 7.01%, #D0ECFB 48.79%, rgba(227, 227, 227, 0) 86.43%);
        user-select: none;
        z-index: 3;
        position: absolute;
        bottom: 0;
        opacity: 100%;
        width: 100%;
        height: 224px;

        @media (max-width: 480px) {
            position: fixed;
            height: 180px;
            z-index: 3;
        }
    }
}

.button-group {
    z-index: 4;
    color: #14457A;
    position: absolute;
    right: 72px;
    top: 65px;
    display: flex;
    
    @media (max-width: 480px) {
        position: fixed;
        right: 0;
        top: 0;
        justify-content: flex-end;
        width: 100%;
        height: 64px;
        background-color: white;
        padding-top: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
        right: 0;
        top: 0;
        justify-content: flex-end;
        width: 100%;
        background-color: white;
        padding-top: 12px;
        padding-right: 24px;
        padding-bottom: 12px;
    }
}

.sign-in-button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    background-color: white;
    width: 94px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color:#14457A;
    margin-right: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    @include hover-active-animation();
}

.sign-up-button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    background-color: #42B3F2;
    width: 94px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-1px);
        color: #14457A;
        background-color:#DBEFFF;
    }
    
    &:active {
        transform: translateY(0px);
        color: white;
        background-color: #376FAB;
    }
}

.clear-button {
    position: absolute;
    top: 12px;
    left: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    background-color: #42B3F2;
    height: 40px;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    display: none;
    z-index: 5;
    gap: 7px;
    transition: filter 0.2s ease-in-out;

    @media (max-width: 480px) {
        position: fixed;
        font-size: 14px;
    }

    img {
        filter: invert(100%) sepia(14%) saturate(7489%) hue-rotate(175deg) brightness(111%) contrast(113%);
    }

    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-1px);
        color: #14457A;
        background-color:white;

        img {
            filter: none;
            filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);
        }
    }

    &:active {
        transform: translateY(0px);
        color: white;
        background-color: #376FAB;

        img {
            filter: none;
            filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);
        }
    }

    &__show {
        display: flex;
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }
}

.sound-button {
    border: none;
    outline: none;
    position: absolute;
    cursor: pointer;
    border-radius: 12px;
    background-color: white;
    height: 40px;
    width: 40px;
    left: 70px;
    top: 65px;
    z-index: 1;
    
    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);

        @include hover-active-image-animation();
    }

    @media (max-width: 480px) {
        top: 12px;
        left: 12px;
        width: 40px;
        height: 40px;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
        top: 12px;
        left: 24px;
        width: 40px;
        height: 40px;
    }

    @include hover-active-animation();

    &__cross {
        position: absolute;
        height: 1.5px;
        border-radius: 8px;
        transition: width 0.5s ease-in-out;
        width: 0;
        background-color: black;
        transform: rotate(45deg);
    
        &__appended {
            transition: width 0.5s ease-in-out;
            width: 40%;
        }
    }
}

.chat-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 3;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.low-opacity {
    opacity: 85%;
}