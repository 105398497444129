@import "../../scss/mixins";

.container {
    position: fixed;
    width: 740px;
    bottom: 42px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27px;
    
    @media (max-width: 480px) {
        width: 100%;
        gap: 11px;
        bottom: 35px;
    }

    
    @media (min-width: 480px) and (max-width: 1023px) {
        width: 100%;
        gap: 27px;
        bottom: 42px;
    }

}

.clear-button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    background-color: #42B3F2;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color:white;
    gap: 7px;
    opacity: 0;
    transform: translateY(24px);
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    img {
        filter: invert(100%) sepia(14%) saturate(7489%) hue-rotate(175deg) brightness(111%) contrast(113%);
    }

    &:hover {
        transform: translateY(-1px);
        color: #14457A;
        background-color:white;
        
        img {
            filter: none;
            filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);
        }
    }
    
    &:active {
        transform: translateY(0px);
        color: white;
        background-color: #376FAB;

        img {
            filter: none;
            filter: invert(100%) sepia(14%) saturate(7489%) hue-rotate(175deg) brightness(111%) contrast(113%);
        }
    }

    &__show {
        opacity: 1;
        transform: translateY(0px);
        transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    }
}

.input-container {
    width: 100%;
    position: relative;
    
    @media (max-width: 480px) {
        width: 90%;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
        width: 80%;
    }
}

.input {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.info-text {
    width: 80%;
    color: #14457A;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    background-color: transparent;
    
    @media (max-width: 480px) {
        font-size: 9px;
        line-height: 10px;
    }
}

.send-button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 1;
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 16px;
    outline: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);

        @include hover-active-image-animation();
    }

    &:hover {
        color: white;
        background-color:#42B3F2;
    }
    
    &:active {
        color: white;
        background-color: #376FAB;
    }
    
    //TODO: Needs adding responsive sizing
}
