@import "../../scss/mixins";

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; 
}

.video-background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  animation-name: blur-out;
  animation-duration: 8s;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: #2F79C8;
  animation-name: fade-out;
  animation-duration: 7s;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    cursor: default;
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    font-size: 104px;
    line-height: 154px;
    text-align: center;
    letter-spacing: 0.03em;
    opacity: 0;
    text-transform: capitalize;
    color: white;

    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 48px;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
      font-size: 64px;
      line-height: 90px;
    }

    animation-name: fade-out-text;
    animation-duration: 4s;
  }
}

.sound-button {
  border: none;
  outline: none;
  position: absolute;
  cursor: pointer;
  border-radius: 12px;
  background-color: white;
  width: 48px;
  height: 48px;
  bottom: 65px;
  left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (max-width: 480px) {
    left: 32px;
    bottom: 32px;
    width: 40px;
    height: 40px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    left: 72px;
    bottom: 64px;
    width: 40px;
    height: 40px;
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    filter: invert(19%) sepia(69%) saturate(1597%) hue-rotate(189deg) brightness(91%) contrast(89%);

    @include hover-active-image-animation();
  }

  @include hover-active-animation();

  &__cross {
      position: absolute;
      height: 1.5px;
      border-radius: 8px;
      transition: width 0.5s ease-in-out;
      width: 0;
      background-color: black;
      transform: rotate(45deg);

      &__appended {
          transition: width 0.5s ease-in-out;
          width: 40%;
      }
  }

  //TODO: Responsive margins should be added
}

.skip-button {
  color: #14457A;
  position: fixed;
  right: 70px;
  bottom: 65px;
  width: 120px;
  height: 40px;
  background-color: white;    
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  @include hover-active-animation();

  @media (max-width: 480px) {
    right: 32px;
    bottom: 32px;
    width: 120px;
    height: 40px;
  }

  @media (min-width: 480px) and (max-width: 1023px) {
    right: 72px;
    bottom: 64px;
    width: 120px;
    height: 40px;
  }

}

@keyframes fade-out-text {
  from {
    display: initial;
    opacity: 100%;
  }

  to {
    opacity: 0%;
    display: hidden;
  }
}

@keyframes fade-out {
  from {
    display: initial;
    opacity: 70%;
  }

  to {
    opacity: 0%;
    display: hidden;
  }
}

@keyframes blur-out {
  from {
    filter: blur(10px);
  }

  to { 
    filter: blur(0px);
  }
}