
.page {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.header-image {

    @media (max-width: 480px) {
        width: 326px;
        height: 90px;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
        width: 520px;
        height: 144px;
    }
}

.second-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    
    color: #14457A;

    @media (max-width: 480px) {
        font-size: 18px;
        line-height: 30px;
    }

    @media (min-width: 480px) and (max-width: 1023px) {
        font-size: 24px;
        line-height: 40px;
    }
}

.loader {
    box-sizing: border-box;
    position: absolute;
    bottom: 65px;
    left: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
        bottom: 32px;
    }
}