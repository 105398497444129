

.fade {

    &-before {
        box-sizing: border-box;
        opacity: 0;
    }

    &-after {
        opacity: 1;
        transition: opacity 600ms linear;
    }

}