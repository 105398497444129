.bubble {
    min-height: 48px;
    max-width: 80%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #14457A;
    white-space: pre-wrap;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-bubble {
    align-self: flex-end;
    background-color: #468ABC;
    color: white;
    border-radius: 16px 16px 4px 16px;
}

.jesus-bubble {
    width: fit-content;
    background-color: white;
    border-radius: 16px 16px 16px 4px;
}

.error-bubble {
    color: white;
    background-color: #D04F4F;
    border-radius: 16px 16px 16px 4px;
}