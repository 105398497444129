
.about-modal {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    z-index: 4;
    padding-top: 120px;
    padding-bottom: 150px;
    padding-left: 150px;
    padding-right: 150px;
    border-radius: 32px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
}

.modal-header {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #376FAB;
}

.modal-header-small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #14457A;
}

.modal-label {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 320px;
    align-items: center;
    justify-content: center;

    &__separator {
        height: 1px;
        width: 96px;
        background-color: #A4B6CC;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #A4B6CC;
    }
}

.modal-button-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 22px;
}

.modal-button {
    border: none;
    outline: none;
    cursor: pointer;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1.5px solid #A4B6CC;
    border-radius: 12px;
}

.hidden {
    display: none;
}
