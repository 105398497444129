
.about-modal {
    z-index: 3;
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    z-index: 4;
    padding-top: 0px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 32px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.modal-header {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    font-size: 63px;
    line-height: 84px;
    text-align: center;
    color: #376FAB;
}

.modal-header-small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #14457A;
}

.modal-button-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 36px;
}

.modal-button {
    border: none;
    outline: none;
    cursor: pointer;
    width: 600px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1.5px solid #A4B6CC;
    border-radius: 12px;
}

.hidden {
    display: none;
}