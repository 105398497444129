@import "../../../../scss/mixins";

.card {
    position: relative;
    user-select: none;
    box-sizing: border-box;
    padding: 18px;
    width: 100%;
    color: #14457A;
    height: 88px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    gap: 27px;
    cursor: pointer;


    @media (max-width: 480px) {
        height: 68px;
        gap: 32px;
    }

    @include hover-active-animation();

    &__text {
        text-align: center;
        max-width: 180px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        
        @media (max-width: 480px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    &__chevron {
        position: absolute;
        right: 22px;
    }
}