
@mixin hover-active-animation() {
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        @media (min-width: 1023px) {
            transform: translateY(-1px);
            color: white;
            background-color:#42B3F2;
        }
    }
    
    &:active {
        @media (min-width: 1023px) {
            transform: translateY(0px);
            color: white;
            background-color: #376FAB;
        }
    }
}

@mixin hover-active-image-animation() {
    transition: filter 0.2s ease-in-out;

    &:hover {
        filter: none;
        filter: invert(100%) sepia(14%) saturate(7489%) hue-rotate(175deg) brightness(111%) contrast(113%);
    }
}
