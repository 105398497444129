.App {
    width: 100vw;
    height: 100vh;
    background: url("./assets/background-overlay.svg");
    backdrop-filter: blur(7.5px);
    background-size: cover;
    position: fixed;
}

.blur {
    background: #ACC4D780;
    opacity: 75%;
    filter: blur(5px);
    transition: filter 600ms linear;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/InterRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
}