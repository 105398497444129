

.chat {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.message-templates {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
    
    @media (max-width: 480px) {
        padding-bottom: 0;
        margin-bottom: 50%;
        margin-top: 10px;
    }

    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.messages {
    padding-top: 150px;
    box-sizing: border-box;
    width: 740px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 240px;

    @media (max-width: 480px) {
        width: 90%;
        max-width: 420px;
    }

   
    @media (min-width: 480px) and (max-width: 1023px) {
        width: 80%;
    }
}