
.loader {
    position: relative;
    width: 100%;
    height: 5px;
    border-radius: 16px;
    background-color: white;

    &__inner {
        width: 0;
        position: absolute;
        border-radius: 16px;
        z-index: 1;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: #42B3F2;;
        transition: width 0.5s ease-in-out;
    }

}

