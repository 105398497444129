@import "../../scss/mixins";

.user-info {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: visible;
    align-items: center;
    
    &__chevron {
        cursor: pointer;
        margin-right: 8px;
        width: 12px;
        height: 12px;

        &__open {
            transform: rotate(180deg);
        }
    }

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 8px;

        &__container {
            position: relative;
            margin: 0;
            padding: 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &__overlay {
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 8px;
                top: 0;
                left: 0;
                opacity: 0;
                background-color: black;
                transition: opacity 0.2s ease-in-out;

                &:hover {
                    opacity: 25%;
                }
            }
        }
    }

    &__details {
        border-radius: 12px;
        position: absolute;
        background-color: white;
        top: calc(100% + 10px);
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 13px;
        padding-bottom: 8px;
        padding-left: 24px;
        padding-right: 24px;
        z-index: 3;

        @media (max-width: 480px) {
            top: calc(100% + 20px);
        }

        &__email {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #14457A;
        }

        &__separator {
            width: 100%;
            height: 1px;
            background-color: #C3DCF1;

            &__top {
                margin-top: 13px;
            }

            &__bottom {
                margin-top: 28px;
            }
        }

        &__balance {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #14457A;
            margin-top: 27px;

            &__bold {
                font-weight: 700;
            }
        }

        &__donate-button {
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 40px;
            margin-top: 12px;
            background-color: #42B3F2;
            border-radius: 12px;
            color: white;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            @include hover-active-animation();
        }

        &__message {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #14457A;
            margin-top: 13px;
        }

        &__button {
            border: none;
            outline: none;
            cursor: pointer;
            height: 26px;
            width: 100%;
            color:#14457A;
            background-color: white;
            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
            margin-top: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 19px;

            &:hover {
                background-color:#DBEFFF;
                border-radius: 4px;
            }

            &:active {
                background-color: #14457A;
                color: white;
            }

        }
    }
}