.about-modal {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 480px) {
        height: 100%;
    }
}

.modal-content {
    position: relative;
    box-sizing: border-box;
    z-index: 4;
    padding: 96px;
    padding-top: 0px;
    padding-bottom: 48px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
    }
}

.modal-button {
    position: absolute;
    outline: none;
    border: none;
    width: 36px;
    height: 36px;
    top: 24px;
    right: 24px;
    background-color:#376FAB;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

}

.modal-button:hover {
    transform: translateY(-1px);
    color: white;
    background-color:#42B3F2;
}

.modal-button:active {
    transform: translateY(0px);
    color: white;
    background-color: white;
}

.modal-text {
    color:#335371;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 431px;
    margin-top: 15px;
}

.modal-picture {
    width: 246px;
    height: 246px;
}