.container {
    display: flex;
    flex-direction: column;
    width: 288px;
    gap: 24px;
    height: fit-content;

    @media (max-width: 480px) {
        gap: 18px;
    }
}

.header {
    font-size: 24px;
    color: #14457A;
    text-align: center;
    line-height: 32px;
    font-weight: 700;
    font-family: 'Libre Baskerville';

    @media (max-width: 480px) {
        font-size: 18px;   
    }

}